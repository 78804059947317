<template>
  <div >
    <div class="row">
      <div class="col-3">
          <label for="text">Date</label>
          <v-datepicker v-model="dateSearch"  range valueType="format" class="dateRange"></v-datepicker>
      </div>
      <!-- <div class="col-3">
        <label for="text">Course</label>
        <b-form-select v-model="courseSearch.selected" :options="courseSearch.options"></b-form-select>
      </div>
      <div class="col-2">
        <label for="text">Major</label>
        <b-form-select v-model="majoreSearch.selected" :options="majoreSearch.options"></b-form-select>
      </div> -->
      <div class="col-3">
        <label for="text">Course & Major</label>
         <b-form-select v-model="courseMajorSearch.selected" :options="courseMajorSearch.options"></b-form-select>
      </div>
       <div class="col-2">
        <label for="text">Level</label>
        <b-form-select v-model="levelSearch.selected" :options="levelSearch.options"></b-form-select>
      </div>
       <div class="col-2">
        <label for="text">Status</label>
        <b-form-select v-model="statusTypeSearch.selected" :options="statusTypeSearch.options"></b-form-select>
      </div>
        <div class="col-2">
        <label for="text">Prefix</label>
        <b-form-select v-model="prefixSearch.selected" :options="prefixSearch.options"></b-form-select>
      </div>
    </div>
    <div class="row row-mt" >
    
      <div class="col-3">
        <label for="text">Fullname</label>
        <b-form-input v-model="fullNameSearch" placeholder="วสันต์ ก่อนลา"></b-form-input>
      </div>
        <div class="col-2">
          <label for="text">Student Code</label>
          <b-form-input v-model="studentCodeSearch" maxlength="11" placeholder="60254789055"></b-form-input>
      </div>
      <div class="col-3 align-self-end">
          <b-button variant="primary" @click="search()">search</b-button>
           <b-button variant="outline-danger" class="ml-1" @click="clearSearch()">clear</b-button>
           
      </div>

    </div>

    <div class="row-table">
     <b-table :fields="fields" :items="items">
         <template #cell(date)="data">
          {{  moment(data.item.date).format("DD-MM-YYYY") }} 
        </template>
        <template #cell(studentCode)="data">
          {{ data.item.student.studentCode }} 
        </template>
         <template #cell(fullName)="data">
         {{ data.item.student.prefix }}  {{ data.item.student.firstName }}  {{ data.item.student.lastName }} 
        </template>
       
        <template #cell(status)="data">
            {{data.item.status}}
        </template>
        </b-table>
          <b-pagination
      v-model="paging.page"
      :total-rows="paging.totalItem"
      :per-page="paging.pageSize"
      @change="changePage"
      aria-controls="my-table"
        ></b-pagination>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BFormSelect,
  BFormDatepicker
} from "bootstrap-vue";
import vDatepicker from 'vue2-datepicker';
import { reactive, ref, onMounted, watch,computed } from '@vue/composition-api';
import { useCheckStudent } from "../useCheckStudent";
import moment from "moment";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useOptionStudent } from "../useOptionStudent";

export default {
  components:{
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    vDatepicker,
    BButton,
    BTable,
    BPagination,
    ToastificationContent
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }){
     let levelSearch = reactive({
      selected: null,
      options: [{ value: null, text: 'all' }]
    })
  let statusTypeSearch = reactive({
      selected: null,
      options: [{ value: null, text: 'all' }]
    })
  let majoreSearch = reactive({
    selected: null,
    options: [ { value: null, text: 'all' }]
  })
    let courseSearch = reactive({
      selected: null,
      options: ref([{ value: null, text: 'all' }]),
    })
    let prefixSearch = reactive({
      selected: null,
      options: ref([{ value: null, text: 'all' },{ value: "นาย", text: 'นาย' },{ value: "นางสาว", text: 'นางสาว' }]),
    })
    let courseMajorSearch = reactive({
      selected: null,
      options: reactive([{ value: null, text: 'all' }])
    })


    let dateSearch = ref([moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD")])
    let studentCodeSearch = ref("")
    let fullNameSearch = ref("")
    let items = ref([]);
    let paging = reactive({
      page: 1,
      pageSize: 10,
      totalPage: 0,
      totalItem: 0,
    });
    let fields = reactive([
      { key: "date", label: "date" },
      { key: "studentCode", label: "student code" },
      { key: "fullName", label: "full name" },
      { key: "status", label: "status" },
    ]);

    const { getDailyreport } = useCheckStudent();
    const {  getCourse, getMajor, getLevel, getStatus } = useOptionStudent();
    const toast = useToast();


    onMounted(async () => {
      await findReportStudents();
      await fetchOptionStudent()
    });


    async function findReportStudents() {
      await getDailyreport({
        page: paging.page,
        pageSize: paging.pageSize,
        startDate: dateSearch.value[0],
        endDate: dateSearch.value[1],
        // majorId: majoreSearch.selected,
        // courseId: courseSearch.selected,
        majorId: courseMajorSearch.selected != null ? courseMajorSearch.selected.majorId : null,
        courseId: courseMajorSearch.selected != null ? courseMajorSearch.selected.courseId : null,
        level: levelSearch.selected,
        status: statusTypeSearch.selected,
        prefix: prefixSearch.selected,
        fullName: fullNameSearch.value,
        studentCode: studentCodeSearch.value
      })
        .then((response) => {
          let { datas, pagination } = response.data;
          paging.page = pagination.currentPage;
          paging.pageSize = pagination.pageSize;
          paging.totalPage = pagination.totalPages;
          paging.totalItem = pagination.totalItems;
          items.value = datas;

        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
    }

    async function changePage(page) {
      paging.page = page;
      await findReportStudents();
    }

    async function search(){
      paging.page = 1;
      await findReportStudents();
    }

    async function clearSearch(){
      paging.page = 1;

      dateSearch.value = [moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD")]
      // majoreSearch.selected = null
      // courseSearch.selected = null
      courseMajorSearch.selected = null
      levelSearch.selected = null
      statusTypeSearch.selected = null
      prefixSearch.selected = null
      fullNameSearch.value = ""
      studentCodeSearch.value = ""

      await findReportStudents();
    }

   async function fetchOptionStudent(){
      try {
          const responses = await Promise.all([getCourse(), getMajor(), getLevel(), getStatus()]);

          const [courseData, majorData, levelData, statusData] = responses;

          /* majorData.data.forEach(item => {
            majoreSearch.options.push({
              value: item.id,
              text: item.name
            })
          }); */

          courseData.data.forEach(item => {
            courseSearch.options.push({
              value: item.id,
              text: item.shortName
            })
          });

          levelData.data.forEach(item =>{
            levelSearch.options.push({
              value: item.level,
              text: `ชั้นปีที่ ${item.level}`
            })
          })

          statusData.data.forEach(item =>{
            statusTypeSearch.options.push({
              value: item,
              text: item
            })
          })

          majorData.data.forEach(item => {
            majoreSearch.options.push({
              value: item.id,
              text: item.name
            })
          }); 

           courseData.data.forEach(course => {
              let options = []
              majorData.data.forEach(major =>{
                if(course.id == major.courseId){
                  let option = {
                    value: { courseId: course.id, majorId: major.id },
                    text: major.name
                  };
                  options.push(option);
                }
              })
                courseMajorSearch.options.push({
                  label: course.shortName,
                  options: options
                })
          }); 

          
        } catch (error) {
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger",  },
          });
        }
    }


   return{
      //value
      prefixSearch, levelSearch, statusTypeSearch, majoreSearch, dateSearch, studentCodeSearch, fullNameSearch, courseSearch,
      fields, items, paging, moment, props, courseMajorSearch,
      //method
      changePage, findReportStudents, search, clearSearch
   }
  }
}
</script>
<style lang="scss" scoped>

.dateRange{
  width: 100%;
}

.row-mt{
  margin-top: 5px;
}

.row-table{
  margin-top: 50px;
}
</style>
<template>
  <div>
    <div class="d-flex justify-content-end" v-if="$can('delete', manageStudentGroup.child.report)">
       <b-button  variant="danger" @click="showModalReset()">Reset</b-button>
    </div>
    <div class="row">
      <!-- <div class="col-3">
        <label for="text">Course</label>
        <b-form-select v-model="courseSearch.selected" :options="courseSearch.options"></b-form-select>
      </div>
       <div class="col-2">
        <label for="text">Major</label>
        <b-form-select v-model="majoreSearch.selected" :options="majoreSearch.options"></b-form-select>
      </div> -->
       <div class="col-3">
        <label for="text">Course & Major</label>
         <b-form-select v-model="courseMajorSearch.selected" :options="courseMajorSearch.options"></b-form-select>
      </div>
       <div class="col-2">
        <label for="text">Level</label>
        <b-form-select v-model="levelSearch.selected" :options="levelSearch.options"></b-form-select>
      </div>
      <div class="col-3 align-self-end">
          <b-button variant="primary" @click="search()">search</b-button>
          <b-button variant="outline-danger" class="ml-1" @click="clearSearch()">clear</b-button>
      </div>
 
     <!--  <div class="col-2 d-flex justify-content-end  align-self-end">
        <b-button variant="success">Excel</b-button>
      </div> -->
    </div>

    <div class="row-table">
      <b-table
      :items="items"
      :fields="fields"
      responsive="sm"
    >
    <!-- <template #thead-top="data">
        <b-tr>
          <b-th></b-th>
          <b-th ></b-th>
          <b-th colspan="2">Status</b-th>
        </b-tr>
      </template> -->
        <template #cell(rowNumber)="data">
            {{ data.item.rowNumber }}
        </template>
         <template #cell(fullName)="data">
          {{ data.item.prefix }}  {{ data.item.firstName }} {{ data.item.lastName }} 
        </template>
          <template #cell(percentage)="data">
          {{ data.item.percentage }}%
        </template>
    </b-table>
        <!-- <b-pagination
          v-model="paging.page"
          :total-rows="paging.totalItem"
          :per-page="paging.pageSize"
          @change="changePage"
          aria-controls="my-table"
        /> -->
    </div>

    <b-modal ref="resetReportModal" hide-backdrop ok-only no-close-on-backdrop hide-footer size="sm"  @close="onCloseResetReportModal">
        <template #modal-title>
          <div class="d-block text-center">
            <h3>Reset report data</h3>
         </div>
       </template>
       <div class="body">
          <b-form @submit="onSubmitResetReport">
            <div class="row">
              <div class="col-12 text-warning font-weight-bold">
                The report should be reset only once per term.
              </div>
            </div>
            <div class="row row-mt">
              <div class="col-8">
                <label for="text-password">Please input password for confirm.</label>
                <b-form-input type="password" v-model="inpuResetReportVal.password"  @keydown="handleKeyDown"  aria-describedby="password-help-block" required></b-form-input>
                <b-form-text id="password-help-block" />
              </div>
              <div class="col-4 d-flex align-items-end">
                <b-button type="submit" variant="primary">Confirm</b-button>
              </div>
            </div>
          </b-form>
       </div>
       </b-modal>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch, computed, toRefs } from "@vue/composition-api";
import {
  BTabs,
  BTab,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BFormSelect,
  BFormDatepicker,
  BTr,
  BTh,
  BForm,
  BFormText
} from "bootstrap-vue";
import vDatepicker from 'vue2-datepicker';
import { useCheckStudent } from "../useCheckStudent";
import { useOptionStudent } from "../useOptionStudent";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ManageStudentGroup   } from '@/libs/acl/resource'

export default {
  components:{
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    vDatepicker,
    BButton,
    BTable,
    BPagination,
    BTr,
    BTh,
    ToastificationContent,
    BForm,
    BFormText
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    let majoreSearch = reactive({
      selected: null,
      options: [{ value: null, text: 'all' },]
    })

   let courseSearch = reactive({
      selected: null,
      options: [{ value: null, text: 'all' },]
    })
    let courseMajorSearch = reactive({
      selected: null,
      options: reactive([{ value: null, text: 'all' }])
    })
    let levelSearch = reactive({
      selected: null,
      options: [ { value: null, text: 'all' } ]
    })
    let paging = reactive({
      page: 1,
      pageSize: 1000,
      totalPage: 0,
      totalItem: 0,
    });
    let fields = reactive( [
      { key: 'rowNumber', label: 'number' },
      { key: 'studentCode', label: 'student code' },
      { key: 'fullName', label: 'full name' },
      { key: 'present', label: 'Checked in' },
      { key: 'absent', label: 'absent' },
      { key: 'percentage', label: 'percent' },
      { key: 'result', label: 'pass' },
     ]);
    let items = ref([]);
    let resetReportModal = ref()
    let inpuResetReportVal = reactive({
      password: "",
    })
    const manageStudentGroup = ManageStudentGroup

    const { getTermreport, resetReport } = useCheckStudent();
    const {  getCourse, getMajor, getLevel, getStatus } = useOptionStudent();
    const toast = useToast();


    onMounted(async () => {
     await fetchOptionStudent()
    });

    watch(() => { return props.active },async (newValue, oldValue) => {
      if(newValue){
        await fetchData()
      }
    });

    async function fetchData(){
        await getTermreport({
        page: paging.page,
        pageSize: paging.pageSize,
        majorId: courseMajorSearch.selected != null ? courseMajorSearch.selected.majorId : null,
        courseId: courseMajorSearch.selected != null ? courseMajorSearch.selected.courseId : null,
        level: levelSearch.selected,
      })
        .then((response) => {
          let { datas, pagination } = response.data;
          paging.page = pagination.currentPage;
          paging.pageSize = pagination.pageSize;
          paging.totalPage = pagination.totalPages;
          paging.totalItem = pagination.totalItems;
          items.value = datas;

        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
    }

   async function changePage(page) {
      paging.page = page;
      await fetchData();
    }

    async function search(){
      paging.page = 1;
      await fetchData();
    }


      async function fetchOptionStudent(){
      try {
          const responses = await Promise.all([getCourse(), getMajor(), getLevel()]);
          const [courseData, majorData, levelData ] = responses;
         /*  majorData.data.forEach(item => {
            majoreSearch.options.push({
              value: item.id,
              text: item.name
            })
          }); */

          courseData.data.forEach(item => {
            courseSearch.options.push({
              value: item.id,
              text: item.shortName
            })
          });

          levelData.data.forEach(item =>{
            levelSearch.options.push({
              value: item.level,
              text: `ชั้นปีที่ ${item.level}`
            })
          })

          courseData.data.forEach(course => {
              let options = []
              majorData.data.forEach(major =>{
                if(course.id == major.courseId){
                  let option = {
                    value: { courseId: course.id, majorId: major.id },
                    text: major.name
                  };
                  options.push(option);
                }
              })
                courseMajorSearch.options.push({
                  label: course.shortName,
                  options: options
                })
          }); 

          
        } catch (error) {
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger",  },
          });
        }
    }

    async function clearSearch(){
      paging.page = 1;
      // majoreSearch.selected = null
      // courseSearch.selected = null
      courseMajorSearch.selected = null
      levelSearch.selected = null
      await fetchData();
    }


   function onCloseResetReportModal(){
    clearInputResetReportModal()
    resetReportModal.value.hide()
   }

   function clearInputResetReportModal(){
    inpuResetReportVal.password = ""
   }

   function showModalReset() {
    resetReportModal.value.show()
   }

  async function onSubmitResetReport(event){
    event.preventDefault()
     await resetReport({
        password: inpuResetReportVal.password,
      }).then(async (response) => {
          if (response.status == 200) {
            toast({
              component: ToastificationContent,
              props: { title: "Successfully delete report.", icon: "CheckCircleIcon", variant: "success",},
            });

            paging.page = 1
            await fetchData()
            onCloseResetReportModal()
          }
        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
  }

    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }

    return{
      //variable
      props, majoreSearch, courseSearch, levelSearch, paging, fields, items, courseMajorSearch,
      inpuResetReportVal, resetReportModal, manageStudentGroup,
      //method
      changePage, search, clearSearch,  onCloseResetReportModal, showModalReset
      ,onSubmitResetReport, handleKeyDown
    }
  },
}
</script>


<style lang="scss" scoped>
.row-table{
  margin-top: 50px;
}
.row-mt{
  margin-top: 15px;
}
</style>
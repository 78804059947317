<template>
  <div class="body">
    <b-tabs
      active-nav-item-class="font-weight-bold text-uppercase text-primary"
      active-tab-class="font-weight-bold text-success"
      content-class="mt-3"
      @activate-tab="changeTab"
    >
      <b-tab title="Daily" active><p>
        <daily-report :active="tabs[0]" /> 
      </p></b-tab>
      <b-tab title="Term">
        <term-report  :active="tabs[1]" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab,
  BTabs
} from "bootstrap-vue";
import DailyReport from './DailyReport';
import TermReport from './TermReport';
import { reactive, ref, onMounted, set, computed } from '@vue/composition-api';
import { useOptionStudent } from "../useOptionStudent";
import { useToast } from "vue-toastification/composition";
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components:{
    BTabs,
    BTab,
    DailyReport,
    TermReport,
    ToastificationContent
  },
  setup(props, { emit }) {
    let tabs = reactive([true, false])
    const {  getCourse, getMajor, getLevel, getStatus } = useOptionStudent();
    const toast = useToast();
    let options = ref()

    onMounted(async () => {
      // await fetchOptionStudent();
    });


    function changeTab(idxTab){
      tabs.forEach((value, index) => {
        if(idxTab == index){
          set(tabs, index, true);
        } else {
          set(tabs, index, false);
        }
      });
    }

/*     async function fetchOptionStudent(){
      try {
          const responses = await Promise.all([getCourse(), getMajor(), getLevel(), getStatus()]);
          const [courseData, majorData, levelData, statusData] = responses;

          const opt = {
            course: courseData.data,
            major: majorData.data,
            level: levelData.data,
            status: statusData.data
          }
          options.value = opt
          // store.commit('manage-student/SET_OPTION', option)
        } catch (error) {
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger",  },
          });
        }
    } */
    

    return{
      //variable
      tabs, options,


      //method
      changeTab
    }
  },
}
</script>


<style lang="scss" scoped>
.body{
  margin-bottom: 50px;
}
</style>